import React from 'react';
import { UserData } from '../../../apis/users/types';
import { DialogProps } from '@mui/material/Dialog';
import {
  useToast,
  ZigButton,
  ZigInput,
  ZigModalActions,
  ZigModalForm,
  ZModal,
} from '@zignaly-open/ui';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { EditEmailValidation } from './validaton';
import { useChangeEmailMutation } from '../../../apis/users/api';

type FormType = Pick<UserData, 'email'> & {
  originalEmail: string;
  confirmEmail: string;
};
const EditEmailModal: React.FC<
  { refetch: () => void; user: UserData; close: () => void } & DialogProps
> = ({ user, refetch, close, ...props }) => {
  const { t } = useTranslation(['users', 'common', 'auth']);
  const [updateEmail, updateEmailStatus] = useChangeEmailMutation();
  const toast = useToast();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<FormType>({
    mode: 'all',
    resolver: yupResolver(EditEmailValidation),
    defaultValues: {
      originalEmail: user.email,
      email: '',
      confirmEmail: '',
    },
  });

  const onSubmit = ({ email }: FormType) => {
    updateEmail({ email, userId: user.userId })
      .unwrap()
      .then(() => {
        toast.success(t('edit-email.success'));
        refetch();
        close();
      })
      .catch((result) => {
        setError('email', {
          type: 'server',
          message: result.data?.error?.msg,
        });
      });
  };

  return (
    <ZModal
      mobileFullScreen
      {...props}
      wide
      close={close}
      title={t('actions.edit-email')}
    >
      <ZigModalForm onSubmit={handleSubmit(onSubmit)}>
        <ZigInput
          id={'edit-key-modal__current-email'}
          wide
          label={t('edit-email.original-email') + ':'}
          placeholder={t('edit-email.email')}
          disabled={true}
          value={user.email}
        />
        <Controller
          name='email'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ZigInput
              id={'edit-key-modal__new-email'}
              wide
              label={t('edit-email.new-email') + ':'}
              placeholder={t('edit-email.email')}
              disabled={updateEmailStatus.isLoading}
              error={t(errors.email?.message, { maxLength: 30 })}
              {...field}
            />
          )}
        />
        <Controller
          name='confirmEmail'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ZigInput
              id={'edit-key-modal__confirm-email'}
              wide
              label={t('edit-email.confirm-email') + ':'}
              placeholder={t('edit-email.email')}
              disabled={updateEmailStatus.isLoading}
              error={t(errors.confirmEmail?.message, { maxLength: 30 })}
              {...field}
            />
          )}
        />
        <ZigModalActions>
          <ZigButton
            id={'edit-email-modal__save'}
            variant={'contained'}
            loading={updateEmailStatus.isLoading}
            disabled={!isValid}
            type='submit'
            size={'large'}
          >
            {t('edit-email.save')}
          </ZigButton>
        </ZigModalActions>
      </ZigModalForm>
    </ZModal>
  );
};

export default EditEmailModal;
