import {
  useToast,
  zignalyBaseThemeConfig,
  createPwaLogoImage,
} from '@zignaly-open/ui';
import {
  WhitelabelBackendConfig,
  WhitelabelBackendConfigPayload,
} from '../../apis/config/types';
import { useTranslation } from 'react-i18next';
import {
  useBustWlCacheMutation,
  useSaveWlConfigMutation,
  useWlConfigQuery,
} from '../../apis/config/api';
import { useAsyncFn } from 'react-use';
import { useUserInfoQuery } from '../../apis/session/api';

export function useSaveConfig(
  wl: string,
  transform?: (
    v: Partial<WhitelabelBackendConfigPayload>,
  ) =>
    | Partial<WhitelabelBackendConfigPayload>
    | Promise<Partial<WhitelabelBackendConfigPayload>>,
) {
  const { t, i18n } = useTranslation('config');
  const [bustCache] = useBustWlCacheMutation();
  const [action] = useSaveWlConfigMutation();
  const { data: originalValue } = useWlConfigQuery(wl);
  const toast = useToast();

  const [{ loading }, submit] = useAsyncFn(
    async (values: Partial<WhitelabelBackendConfigPayload>) => {
      try {
        await action({
          slug: wl,
          data: {
            ...(transform ? await transform(values) : values),
          } as WhitelabelBackendConfigPayload,
        }).unwrap();
        try {
          await bustCache(originalValue.domain).unwrap();
        } catch (e) {
          toast.error(t('error.save-ok-cache-clear-not-ok'));
        }
        toast.success(t('saved'));
      } catch (e) {
        console.error(e);
        toast.error(
          t('failed') +
            ' ' +
            (e?.data?.error?.msg
              ? i18n.exists(`error:error.${e?.data?.error?.msg}`)
                ? t(`error:error.${e?.data?.error?.msg}`)
                : e?.data?.error?.msg
              : ''),
        );
      }
    },
    [t],
  );

  return {
    isLoading: loading,
    submit,
  };
}

export function useRegenerateImages(wlConfig: WhitelabelBackendConfig): (
  logo?: string,
  bgColor?: string,
  force?: boolean,
) => Promise<
  Partial<{
    pwaLogo: File;
    pwaLogoMac: File;
    splashscreen: File;
  }>
> {
  return async (logo, bgColor, force) => {
    const [oldLogo, oldColor] = [
      wlConfig.favicon,
      wlConfig.themeOverride?.themeOverrides?.palette?.neutral800 ||
        zignalyBaseThemeConfig.palette.neutral800,
    ];
    const [newLogo, newColor] = [logo || oldLogo, bgColor || oldColor];
    if (newLogo === oldLogo && newColor === oldColor && !force) return {};
    const logoSrc =
      typeof newLogo === 'string' ? newLogo + '/original' : newLogo;
    return {
      pwaLogo: await createPwaLogoImage(logoSrc, newColor, 'icon', {
        width: 512,
        height: 512,
        logoWidth: 330,
        logoHeight: 330,
      }),
      splashscreen: await createPwaLogoImage(logoSrc, newColor, 'splash', {
        width: 1536,
        height: 2688,
        logoWidth: 400,
        logoHeight: 400,
      }),
      pwaLogoMac: await createPwaLogoImage(logoSrc, newColor, 'macos-icon', {
        width: 512,
        height: 512,
        logoWidth: 246,
        logoHeight: 246,
      }),
    };
  };
}

export function downloadFile(file: File) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

export function useCurrentlyManagedWl(): string {
  const { data } = useUserInfoQuery();
  return data?.projectIds?.[0];
}
